import React from "react";
// nodejs library that concatenates classes
// core components
import { Grid } from "@material-ui/core";

import profileAA from "images/profile.jpg";
// import team2 from "assets/img/faces/christian.jpg";
// import team3 from "assets/img/faces/kendall.jpg";

export default function TeamSection() {
  return (
    <div>
      <p className={"Title"}>Meet Anthony F. Anise</p>
      <div>
        <Grid container>
          <Grid item container xs={12} sm={12} md={12}>
            <div>
              <div
                style={{
                  width: "30%",
                  float: "right",
                  background: "#333",
                  borderRadius: 10,
                  overflow: "hidden",
                  margin: "10px",
                }}
              >
                <img src={profileAA} alt="..." className={"ProfilePicture"} />
                <p
                  className={"Subtitle"}
                  style={{
                    textAlign: "center",
                    margin: 5,
                    fontSize: "1.8vw",
                    fontWeight: 200,
                    fontVariant: "normal",
                  }}
                >
                  Anthony F. Anise
                </p>
              </div>
              <p className={"Text"}>
                Attorney Anthony F. Anise is a Fort Lauderdale criminal defense
                attorney who practices in Miami-Dade, Broward, and Palm Beach
                counties. He has a reputation for both drive and determination.
                During his time as a public defender, he made efforts to really
                get to know the needs of his clients, as well as forge good
                relationships in the legal community. Mr. Anise earned their
                respect as well as the respect of his peers and his clients.
              </p>
              <p className={"Text"}>
                Mr. Anise takes every case seriously and is committed to
                defending every one of the people who come to him for help. He
                knows that the outcome of each case is important to his clients,
                so his first goal is to give them a sense of hope tempered with
                the reality of the situation and the law. He will do all he can
                provide a strong defense and ensure his clients receive a fair
                hearing in court.
              </p>
              <p className={"Text"}>
                He has a wide range of interests and has taught himself web
                development, coding, and scores of other things by trial and
                error.
              </p>
              <p className={"Text"}>
                Mr. Anise began his legal career as an attorney for the Law
                Office of the Public Defender of the 17th Judicial District in
                Broward County from 2014 to 2016. He has won awards, including
                the prestigious Hat Trick Award presented by the Broward
                Association of Criminal Defense Lawyers for having won five jury
                trials in a row. As a public defense attorney, Mr. Anise handled
                a veriety of cases ranging from misdemeanors to serious
                felonies. His work in public defense required him to handle
                between 140 - 160 at the same time for everything from traffic
                violations DUI cases, drug charges, domestic violence, and other
                criminal charges.
              </p>
              <p className={"Text"}>
                Mr. Anise graduated with <b>honors</b>, earning a Juris Doctor
                degree from the University of Miami School of Law in 2012. At
                the age of 22, he was the youngest member of his graduating
                class. After two years, he left the Law Office of the Public
                Defender of the 17th Judicial District and founded his own law
                practice.
              </p>
              <p className={"Text"}>
                Mr. Anise has handled over 2000 criminal cases in Palm Beach and
                Broward Counties
              </p>
            </div>
          </Grid>
          {/* <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Christian Louboutin
                <br />
                <small className={classes.smallTitle}>Designer</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#pablo">links</a> for people to be able to
                  follow them outside the site.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team3} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Kendall Jenner
                <br />
                <small className={classes.smallTitle}>Model</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#pablo">links</a> for people to be able to
                  follow them outside the site.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem> */}
        </Grid>
      </div>
    </div>
  );
}
