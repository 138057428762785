import React from "react";
// react component for creating beautiful carousel
import { Grid, Button } from "@material-ui/core";
import Slider from "react-slick";
// @material-ui/core components
// @material-ui/icons
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SectionCarousel() {
  const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    adaptiveHeight: true,
  };
  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  let slides = shuffle([
    {
      quote: "Recommend him to anyone fearing they have no chance in court.",
      name: "Bryan",
    },
    {
      quote:
        "I recommend Anthony Anise to ALL, who are looking for an criminal attorney that has proved to be a winner and will advise you and guide your case/s every step along the way while doing so.",
      name: "Satisfied Client",
    },
    {
      quote:
        "Had a guaranteed jail time due to violation of probation and driving with suspended license. Anthony resolved both cases in one day with no jail time.",
      name: "Bryan",
    },
    {
      quote: "He did a great job and was super fast.",
      name: "Ashley",
    },
    {
      quote:
        "He handled both of my cases from beginning to end with the utmost care and due diligence, thus both resulting in dismissals of all criminal charges.",
      name: "Satisfied Client",
    },
    {
      quote:
        "He did an amazing job in less than one hour I was in and out of court happy as can be.",
      name: "Ashley",
    },
    {
      quote:
        "Anthony gave me weekly status updates on each case and his evolving attack plan to resolve both of them, as he eventually did.",
      name: "Satisfied Client",
    },
    {
      quote:
        "he cares deeply about his clients and would go the extra mile and beyond for them. I deeply and sincerely recommend him to anyone who needs a strong, intelligent attorney.",
      name: "Inel",
    },
    {
      quote:
        "I'm glad I hired Anthony for my criminal traffic case, he made no promises just to gain a client. He was always open and transparent, and very honest. Mr. Anthony worked hard and got my case dismissed, I will definitely refer him to others.",
      name: "Sam",
    },
    {
      quote:
        "I called Mr. Anise's office a couple of days before my court date, he insured me he could handle my case in such a short notice. I'm glad to say that my case has been DISMISSED.",
      name: "Satisfied Client",
    },
    {
      quote:
        "Anthony handled my case professionally and expeditiously with zero extra charges. Hard to find lawyers like Anthony, and I am very happy our roads crossed.",
      name: "Kim",
    },
    {
      quote:
        "Very prompt and follows up with any updates on your case. Mr. Anise really worked with me & got me out of trouble!!",
      name: "Satisfied Client",
    },
    {
      quote:
        " Mr Anthony, you are truly a counselor, a wonderful human being, and a Lawyer with a great big heart.",
      name: "Inel",
    },
    {
      quote:
        "He was very attentive and worked diligently on my case to get it dismissed quickly.",
      name: "Catherine",
    },
    {
      quote:
        "He walked me through the entire process, and after I hired him he kept in touch regularly to give me updates on the case. He went to court for me saving me much time. ",
      name: "Victor",
    },
    {
      quote:
        "Great Lawyer! Got my charge dismissed! He made the situation a lot better. He was very professional.",
      name: "Rodnie",
    },
    {
      quote:
        "I first began with Mr.anise and he was assuring, helpful and got right to the point. Honest and did an excellent job with both of my cases.",
      name: "John",
    },
    {
      quote:
        "Mr Anise got my case dismissed. He did a great job keeping me informed with all the details of the case. My goal was to get the case dismissed and the mission got accomplished.",
      name: "Satisfied Client",
    },
    {
      quote:
        "Attorney Anthony is the best! He got my case dismissed within 7 months. I would rehire him again if needed and recommend him to anyone who is in need.",
      name: "Jahmar",
    },
    {
      quote:
        "On the day I was schedueled for trial, Anthony was prepared and confident which eased my stress and anxiety. The outcome was better then I could of asked for, both cases were dropped completely!!",
      name: "Victor",
    },
    {
      quote:
        "So I would most definitely reccomend Mr. Anise to friends and family. I’m a very satisfied client!",
      name: "Victor",
    },
    {
      quote:
        "Despite being states away from Anthony, he handled both of my cases from beginning to end with the utmost care and due diligence, thus both resulting in dismissals of all criminal charges.",
      name: "Satisfied Client",
    },
    {
      quote:
        "Despite being states away from Anthony, he handled both of my cases from beginning to end with the utmost care and due diligence, thus both resulting in dismissals of all criminal charges.",
      name: "Satisfied Client",
    },
  ]);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={"Title"}>See What Clients have to say About Us</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Slider {...settings}>
            {slides.map(function (slide) {
              return (
                <div key={slide}>
                  <div
                    style={{
                      padding: 5,
                      textAlign: "center",
                      background: "#333",
                      borderRadius: "20px",
                    }}
                  >
                    <h4>
                      {'"...'}
                      {slide.quote}
                      {'..."'}
                    </h4>
                    <h2>
                      {" - "}
                      {slide.name}
                    </h2>
                    <h3>{""}</h3>
                  </div>
                </div>
              );
            })}
          </Slider>
        </Grid>
        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <Button
            href={
              "https://www.avvo.com/attorneys/33301-fl-anthony-anise-4501301/reviews.html"
            }
            target="_blank"
            variant={"contained"}
            color="primary"
          >
            See More Testimonials
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <p className={"Caption"}>
            Please keep in mind that these statements are the experiences and
            opinions of past clients of the firm, and that the success of any
            legal matter depends on the unique circumstances of each case.
            Future or prospective clients may not obtain the same or similar
            results as those experienced by the clients in these testimonials
            above.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
