import React from "react";
import "App.css";

import { Grid } from "@material-ui/core";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import LogoOnBlack from "images/Logo-OnBlack.png";

// Sections for this page
import Intro from "Sections/IntroSection.js";
import DFSection from "Sections/DFSection.js";
import Testimonials from "Sections/Testimonials.js";
import AboutSection from "Sections/AboutSection.js";
import WorkSection from "Sections/WorkSection.js";

let dashboardRoutes = [];

export default function LandingPage(props) {
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand={(color) =>
          color === "black" ? (
            <img src={LogoOnBlack} alt={"logo"} style={{ maxHeight: 40 }} />
          ) : (
            <img src={LogoOnBlack} alt={"logo"} style={{ maxHeight: 40 }} />
          )
        }
        rightLinks={
          <>
            <div>(954) 715-6001 | LawFirm@AniseDefense.com</div>
            <HeaderLinks />
          </>
        }
        fixed
        changeColorOnScroll={{
          height: 800,
          color: "darkDown",
        }}
        {...rest}
      />
      <Parallax filter image={require("images/Face.jpg")}>
        <div style={{ width: "100%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} style={{ height: 250 }}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h1
                className={"Title"}
                style={{
                  fontWeight: 200,
                  fontSize: "3.5vw",
                  color: "#fff",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                The Best Defense is a Dismissal.
              </h1>
              <br />
              <h1
                className={"Title"}
                style={{
                  fontWeight: 200,
                  fontSize: "3vw",
                  color: "#fff",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                - Anthony Anise
              </h1>
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <div className={"MainContainer"}>
        <div className={"MainSection"}>
          <Intro />
        </div>{" "}
        <div id={"Testimonials"} className={"MainSection"}>
          <Testimonials />
        </div>
        <div id={"DismissalFirst"} className={"MainSection"}>
          <DFSection />
        </div>
        <div id={"About"} className={"MainSection"}>
          <AboutSection />
        </div>
        <div className={"MainSection"}>
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
