import React from "react";

export default function Footer(props) {
  return (
    <footer style={{ textAlign: "center", paddingBottom: 20, color: "#fff" }}>
      <div className={"Text"}>
        © 2020. The Law Office of Anthony F. Anise, P.L.L.C.
        <br />
      </div>
    </footer>
  );
}
