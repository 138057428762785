import React from "react";
// @material-ui/core components

import { Grid } from "@material-ui/core";

export default function Contact() {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <p className={"Title"}>
            Contact Anthony Anise for a Free Case Evaluation:
          </p>

          <p className={"Text"}>
            To schedule an appointment for a free case evaluation, please
            contact us.
          </p>
          <p className={"Text"}>
            Telephone:
            <br />
            <span className={"Subtitle"}>(954) 715-6001</span>
          </p>
          <p className={"Text"}>
            Email:
            <br />
            <span className={"Subtitle"}>LawFirm@AniseDefense.com</span>
          </p>
          <p className={"Text"}>
            Address:
            <br />
            <span className={"Subtitle"}>
              110 S.E. 6th Street, Suite 1700,
              <br />
              Fort Lauderdale, FL, 33301
            </span>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
