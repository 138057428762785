import React from "react";
// @material-ui/core components

import { Grid } from "@material-ui/core";

export default function Intro() {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <p className={"Title"}>Fighting the Battle</p>

          <p className={"Text"}>
            A criminal charge is one of the most distressing experiences that
            the government can inflict on its citizens. When you are charged
            with a crime, you need an advocate in your corner, ready to fight
            your battle.
          </p>
          <p className={"Text"}>
            Attorney Anthony Anise with take on the government with unique and
            effective solutions. Attorney Anise starts with the DismissalFirst
            initiative. Working to get the case dismissed before anything else.
            When charged with a crime, you should always fight for a dismissal
            before accepting anything else. That is the commitment of our firm.
            Learn more about how our DismissalFirst initiative works.
          </p>
          <p className={"Title"}>Protecting Your Rights </p>
          <p className={"Text"}>
            Most people know that the U.S. Constitution has protections for
            everyone charged with a crime. However, those rights are the tip of
            the iceberg. There are also many other protections found in the
            Florida Constitution and the state statutory law, which do more than
            the Federal Constitution. These rights are the invisible tools that
            Anthony Anise uses to protect his clients from abuse and overreach
            by law enforcement, the State Attorney’s Office, and the members of
            the criminal judiciary process.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
