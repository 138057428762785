import React from "react";
// @material-ui/core components
import { Grid, colors } from "@material-ui/core";

// @material-ui/icons
import DoneAllIcon from "@material-ui/icons/DoneAll";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PeopleIcon from "@material-ui/icons/People";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components

export default function ProductSection() {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <p className={"Title"}>What is DismissalFirst?</p>
          <p className={"Text"}>
            Did you know that there are hundreds of ways to get a criminal
            charge dismissed? Some cases can be dismissed by the judge directly.
            In others, we use the law to exclude or strike so much evidence that
            the state can no longer move forward and must dismiss it. Other
            times, after a strong showing from your legal team, the government
            feels that they have no other choice but to dismiss the case or
            lower the charges.
          </p>
        </Grid>
      </Grid>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Checklist"
              description="At Anise Criminal Defense, we put your case through a rigorous DismissalFirst checklist, catching the details of the law that can be easily missed."
              icon={DoneAllIcon}
              iconColor={colors.green[500]}
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Commitment"
              description="Many cases can be won and lost in this stage. If your case is not fought with the DismissalFirst commitment, it may proceed to the unnecessary risk and expense of trial."
              icon={VerifiedUser}
              iconColor={colors.blue[500]}
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Consultation"
              description="Take advantage of the DismissalFirst system. Contact Anthony Anise today and you could qualify for a free consultation."
              icon={PeopleIcon}
              iconColor={colors.orange[500]}
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function InfoArea(props) {
  return (
    <div className="InfoArea">
      {props.icon ? (
        <props.icon
          style={{ fontSize: 40, color: props.iconColor }}
          color={props.iconColor}
        />
      ) : null}
      <div className="Subtitle">{props.title}</div>
      <div className="InfoText">{props.description}</div>
    </div>
  );
}
